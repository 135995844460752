var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", "max-width": "500px" } },
    [
      _c(
        "v-card",
        { attrs: { color: "fill" } },
        [
          _c(
            "v-toolbar",
            { staticClass: "main", attrs: { flat: "", dark: "" } },
            [_c("v-toolbar-title", [_vm._v(" Generate Exception Reports ")])],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "my-0 py-0" },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _vm._v(
                          " Select an ad date and/or TPR start date to generate contract promo exception report and missing linked items report. "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                transition: "scale-transition",
                                "offset-y": "",
                                "max-width": "290px",
                                "min-width": "290px"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                label: "Ad Date",
                                                type: "date",
                                                "prepend-inner-icon":
                                                  "mdi-calendar",
                                                dense: "",
                                                "background-color": "#fff",
                                                outlined: "",
                                                "hide-details": "",
                                                clearable: ""
                                              },
                                              model: {
                                                value: _vm.selectedAdDate,
                                                callback: function($$v) {
                                                  _vm.selectedAdDate = $$v
                                                },
                                                expression: "selectedAdDate"
                                              }
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.ad_dt_picker,
                                callback: function($$v) {
                                  _vm.ad_dt_picker = $$v
                                },
                                expression: "ad_dt_picker"
                              }
                            },
                            [
                              _c("v-date-picker", {
                                attrs: {
                                  "show-current":
                                    _vm.selectedTPRStartDate || true,
                                  scrollable: "",
                                  "no-title": ""
                                },
                                model: {
                                  value: _vm.selectedAdDate,
                                  callback: function($$v) {
                                    _vm.selectedAdDate = $$v
                                  },
                                  expression: "selectedAdDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                transition: "scale-transition",
                                "offset-y": "",
                                "max-width": "290px",
                                "min-width": "290px"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                label: "TPR/EDLC Start Date",
                                                type: "date",
                                                "prepend-inner-icon":
                                                  "mdi-calendar",
                                                dense: "",
                                                "background-color": "#fff",
                                                outlined: "",
                                                "hide-details": "",
                                                clearable: ""
                                              },
                                              model: {
                                                value: _vm.selectedTPRStartDate,
                                                callback: function($$v) {
                                                  _vm.selectedTPRStartDate = $$v
                                                },
                                                expression:
                                                  "selectedTPRStartDate"
                                              }
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.start_dt_picker,
                                callback: function($$v) {
                                  _vm.start_dt_picker = $$v
                                },
                                expression: "start_dt_picker"
                              }
                            },
                            [
                              _c("v-date-picker", {
                                attrs: {
                                  "allowed-dates": _vm.allowedDates,
                                  "show-current": _vm.selectedAdDate || true,
                                  scrollable: "",
                                  "no-title": ""
                                },
                                model: {
                                  value: _vm.selectedTPRStartDate,
                                  callback: function($$v) {
                                    _vm.selectedTPRStartDate = $$v
                                  },
                                  expression: "selectedTPRStartDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", disabled: _vm.loading },
                      on: { click: _vm.close }
                    },
                    [_vm._v(" Cancel ")]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.loading,
                        disabled: _vm.disabled || _vm.loading
                      },
                      on: { click: _vm.generateReports }
                    },
                    [_vm._v(" Generate ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }