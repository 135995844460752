<template>
  <v-dialog :value="value" persistent max-width="500px">
    <v-card color="fill">
      <v-toolbar flat dark class="main">
        <v-toolbar-title>
          Generate Exception Reports
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="my-0 py-0">
        <v-container fluid>
          <v-row>
            <v-col>
              Select an ad date and/or TPR start date to generate contract promo exception report and missing linked items report.
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                v-model="ad_dt_picker"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedAdDate"
                    label="Ad Date"
                    type="date"
                    prepend-inner-icon="mdi-calendar"
                    dense
                    background-color="#fff"
                    outlined
                    hide-details
                    clearable
                    v-bind="attrs"
                    v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker 
                  v-model="selectedAdDate"
                  :show-current="selectedTPRStartDate || true"
                  scrollable
                  no-title>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                v-model="start_dt_picker"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedTPRStartDate"
                    label="TPR/EDLC Start Date"
                    type="date"
                    prepend-inner-icon="mdi-calendar"
                    dense
                    background-color="#fff"
                    outlined
                    hide-details
                    clearable
                    v-bind="attrs"
                    v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker 
                  v-model="selectedTPRStartDate"
                  :allowed-dates="allowedDates"
                  :show-current="selectedAdDate || true"
                  scrollable
                  no-title>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-btn 
            text
            :disabled="loading" 
            @click="close">
            Cancel
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn 
            color="primary"
            :loading="loading" 
            :disabled="disabled || loading"
            @click="generateReports">
            Generate
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ContractUpload from '@/axios/contract-upload-endpoint.js'

// mixins
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
export default {
  name: 'ExceptionReportModal',
  data() {
    return {
      allowedDates: val => new Date(val).getDay() === 6,
      ad_dt_picker: false,
      start_dt_picker: false,
      selectedAdDate: null,
      selectedTPRStartDate: null,
      loading: false
    }
  },
  mixins: [displayAlert, userAccess, utils],
  props: {
    value: Boolean,
  },
  computed: {
    disabled() {
      return (!this.selectedTPRStartDate && !this.selectedAdDate)
    }
  },
  methods: {
    close() {
      this.$emit('closeExceptionModal')
    },
    async generateReports() {
      this.loading = true
      try {
        const params = {
          ad_date: this.selectedAdDate, 
          tpr_start_date: this.selectedTPRStartDate
        }
        const promises = [
          ContractUpload.getExceptionReport(params),
          ContractUpload.getMissingItemsReport(params)
        ]
        const [exceptionRes, missingItemsRes] = await Promise.all(promises)
        if (exceptionRes?.data?.url) {
          location.href = exceptionRes.data.url
        }
        if (missingItemsRes?.data?.url) {
          setTimeout(() => {
            location.href = missingItemsRes.data.url
          }, 1000)
        }
        this.emitAlert(true, 'success', "Reports generated successfully", [], true)
        this.$emit('closeExceptionModal')
      } catch (error) {
        this.handleError(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>